import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://organization.api.acesolutions.no/api/Swagger/ui#/

export interface StoreGroup {
    id?: string,
    organizationId: string,
    name: string
}

export interface StoreGroupEdge {
    id?: string,
    organizationId: string,
    storeGroupId: string,
    storeId: string,
    storeName: string
}

export const GetStoreGroups = async (org_id: string): Promise<StoreGroup[]> => {
    try {
        const { data } = await axios.get<StoreGroup[]>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch store groups:', error);
        throw error;
    }
}

export const GetStoreGroup = async (group_id: string, org_id: string): Promise<StoreGroup> => {
    try {
        const { data } = await axios.get<StoreGroup>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch store group:', error);
        throw error;
    }
}

export const CreateStoreGroup = async (storeGroup: StoreGroup): Promise<StoreGroup> => {
    try {
        const { data } = await axios.post<StoreGroup>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${storeGroup.organizationId}/storeGroups`, storeGroup, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create store group:', error);
        throw error;
    }
}

export const UpdateStoreGroup = async (storeGroup: StoreGroup): Promise<StoreGroup> => {
    try {
        const { data } = await axios.put<StoreGroup>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${storeGroup.organizationId}/storeGroups/${storeGroup.id}`, storeGroup, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update store group:', error);
        throw error;
    }
}

export const DeleteStoreGroup = async (group_id: string, org_id: string) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete store group:', error);
        throw error;
    }
}

export const GetStoreGroupEdges = async (group_id: string, org_id: string): Promise<StoreGroupEdge[]> => {
    try {
        const { data } = await axios.get<StoreGroupEdge[]>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}/storeGroupStoreEdges`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch store group edges:', error);
        throw error;
    }
}

export const GetStoreGroupEdge = async (edge_id: string, group_id: string, org_id: string): Promise<StoreGroupEdge> => {
    try {
        const { data } = await axios.get<StoreGroupEdge>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}/storeGroupStoreEdges/${edge_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch store group edge:', error);
        throw error;
    }
}

export const CreateStoreGroupEdge = async (group_id: string, org_id: string, storeGroupEdge: StoreGroupEdge): Promise<StoreGroupEdge> => {
    try {
        const { data } = await axios.post<StoreGroupEdge>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}/storeGroupStoreEdges`, storeGroupEdge, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create store group edge:', error);
        throw error;
    }
}

export const UpdateStoreGroupEdge = async (edge_id: string, group_id: string, org_id: string, storeGroupEdge: StoreGroupEdge): Promise<StoreGroupEdge> => {
    try {
        const { data } = await axios.put<StoreGroupEdge>(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}/storeGroupStoreEdges/${edge_id}`, storeGroupEdge, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update store group edge:', error);
        throw error;
    }
}

export const DeleteStoreGroupEdge = async (edge_id: string, group_id: string, org_id: string) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_STORE_GROUP_API}/api/organizations/${org_id}/storeGroups/${group_id}/storeGroupStoreEdges/${edge_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete store group edge:', error);
        throw error;
    }
}
