import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Layout from '../../template/layout';
import MainWidget from '../../components/widgets/MainWidget';

import { GetVisitorsStatistics, Statistics } from '../../api/visitation';
import { GetOrganizationStatistics, DashboardStatistics } from '../../api/organization';

import Chart, { CategoryScale } from 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";
import { GetAllSatisfactions } from '../../api/satisfaction';
Chart.register(CategoryScale);

export default function Dashboard() {
  const loggedUser = useSelector((state:any) => state.auth.logged);
  
  const organization_id = loggedUser.extension_organizationId;
  const [visitors, setVisitors] = useState<any>([]);
  const [satisfactions, setSatisfactions] = useState<any>([]);

  const [statistics, setStatistics] = useState<DashboardStatistics | null>(null);

  useEffect(() => {
    localStorage.removeItem("store_group_id");
    localStorage.removeItem("store_group_name");
  }, []);

  const loadStatistics = () => {
    organization_id && GetVisitorsStatistics(localStorage.getItem('organization_id') || organization_id)
      .then(data => {
          setVisitors([
            data?.visitor_statistics?.checked_in,
            data?.visitor_statistics?.checked_out,
            data?.visitor_statistics?.pre_registered
          ]);
      })
      .catch(error => {
          console.log(error);
      });
      
    organization_id && GetAllSatisfactions(localStorage.getItem('organization_id') || organization_id)
      .then(data => {    
          setSatisfactions([
            data?.summary?.distribution['1'],
            data?.summary?.distribution['2'],
            data?.summary?.distribution['3'],
            data?.summary?.distribution['4'],
          ]);
      })
      .catch(error => {
        console.log(error);
      });

    organization_id &&  GetOrganizationStatistics(localStorage.getItem('organization_id') || organization_id)
      .then(data => {    
          // console.log(data);
          setStatistics(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadStatistics();
    const timer = setInterval(() => loadStatistics(), 30000);
    return () => clearInterval(timer);
  }, [loggedUser]);

  return (
    <Layout>
        <Box
            component="main"
            className="MainContent"
            sx={{
            px: { xs: 2, md: 6 },
            pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column', 
            minWidth: 0,
            gap: 1,
            }}
            style={{
              position: 'relative',
              maxWidth: '1400px',
              margin: '0 auto'
            }}
        >
            

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon />}
                sx={{ pl: 0 }}
            >
                <Link
                underline="none"
                color="neutral"
                aria-label="Home"
                >
                <HomeRoundedIcon />
                </Link>
                <Typography color="primary" fontWeight={500} fontSize={12}>
                Dashboard
                </Typography>
            </Breadcrumbs>
            </Box>
            <Box
            sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
            >
              <Typography level="h2" component="h1" marginBottom={'2rem'}>
                Welcome, {loggedUser.given_name}
              </Typography>
              <Grid xs={12} container
                rowSpacing={{ xs: 2, sm: 3 }}
                columnSpacing={{ xs: 0, sm: 3 }}
                sx={{ flexGrow: 1 }}
                className="tiles"
              >
                {loggedUser.extension_customClaims === 'super.admin' &&
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget description={"Only for Super Admin"} status={"primary"} title={'Organizations'} icon={'organizations'} url={'/organizations'} image={'photo-1517502884422-41eaead166d4'} />
                </Grid>}
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Stores'} icon={'stores'} url={'/stores'} image={'photo-1655522060985-6769176edff7'}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%' }}
                    >
                      <Grid xs={4} sm={4} md={4} lg={4} xl={4} >
                        <Typography textAlign={'center'} sx={{ color: '#10f600',  fontSize: { xs: '4rem', sm: '4rem', md: '4rem', lg: '3rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.storeStatistics?.openStores || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Open</Typography>
                      </Grid>
                      <Grid xs={4} sm={4} md={4} lg={4} xl={4} >
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: '4rem', sm: '4rem', md: '4rem', lg: '3rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.storeStatistics?.closedStores || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Closed</Typography>
                      </Grid>
                      <Grid xs={4} sm={4} md={4} lg={4} xl={4} >
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: '4rem', sm: '4rem', md: '4rem', lg: '3rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.storeStatistics?.automaticStores || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Auto</Typography>
                      </Grid>
                    </Grid>
                  </MainWidget>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Devices'} icon={'devices'} url={'/devices'} image={'photo-1634224147987-95d2b7679fb0'}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%' }}
                    >
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ color: '#10f600', fontSize: { xs: '4rem', sm: '4rem', md: '4rem', lg: '3rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.deviceStatistics?.activeDevices || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Operating</Typography>
                      </Grid>
                      <Grid xs={3} sm={3} md={3} lg={3} xl={3} >
                        <Typography textAlign={'center'} sx={{color: '#ff0000', fontSize: { xs: '4rem', sm: '4rem', md: '4rem', lg: '3rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.deviceStatistics?.failureDevices || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Failure</Typography>
                      </Grid>
                      <Grid xs={3} sm={3} md={3} lg={3} xl={3} >
                        <Typography textAlign={'center'} sx={{color: '#ffffff', fontSize: { xs: '4rem', sm: '4rem', md: '4rem', lg: '3rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.deviceStatistics?.offlineDevices || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Offline</Typography>
                      </Grid>
                    </Grid>
                  </MainWidget>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Zones'} icon={'zones'} url={'/zones'} image={'photo-1552028650-e8207c3026f9'}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%' }}
                    >
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ color: '#f60000',  fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.zoneStatistics?.highActivityZones || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">High</Typography>
                      </Grid>
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ color: '#ffd500',  fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.zoneStatistics?.lowActivityZones || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Low</Typography>
                      </Grid>
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ color: '#10f600',  fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.zoneStatistics?.moderateActivityZones || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Moderate</Typography>
                      </Grid>
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{color: '#ffffff', fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.zoneStatistics?.inactiveZones || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Inactive</Typography>
                      </Grid>
                    </Grid>
                  </MainWidget>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Users'} icon={'users'} url={'/users'} image={'photo-1568992687947-868a62a9f521'}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ width: '100%' }}
                    >
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ color: '#10f600',  fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.userStatistics?.activeUsers || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">In store</Typography>
                      </Grid>
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.userStatistics?.backWatchUsers || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Back-watch</Typography>
                      </Grid>
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.userStatistics?.vacationingUsers || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Vacation</Typography>
                      </Grid>
                      <Grid xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Typography textAlign={'center'} sx={{color: '#ffd500', fontSize: { xs: '4rem', sm: '3rem', md: '2rem', lg: '2.7rem', xl: '3rem' }, lineHeight: 1, alignItems: 'flex-start' }}>{statistics?.userStatistics?.offWorkUsers || 0}</Typography>
                        <Typography textAlign={'center'} level="title-md">Off work</Typography>
                      </Grid>
                    </Grid>
                  </MainWidget>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Visitors'} icon={'visitors'} url={'/visitors'} image={'photo-1634482897361-7f327bb7d009'}>
                    <Doughnut
                        style={{ flex:1, maxHeight: '60%'}}
                        data={{
                            labels: ["Checked In", "Checked Out", "Pre Registered"],
                            datasets: [
                                {
                                    data: visitors,
                                    backgroundColor: ["#ec5050", "#5bff61", "#ffffff"],
                                    borderWidth: 2,
                                    borderColor: '#3286ce',
                                    hoverOffset: 4
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: true,
                                    position: 'right',
                                    labels: {
                                        color: '#ffffff'
                                    }
                                }
                            },
                        }}
                    />
                  </MainWidget>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Satisfactions'} icon={'satisfactions'} url={'/satisfactions'} image={'photo-1554774853-b415df9eeb92'} >
                    <Doughnut
                        style={{ flex:1, maxHeight: '60%'}}
                        data={{
                            labels: ["Bad", "Solid", "Good", "Excelent"],
                            datasets: [
                                {
                                    data: satisfactions,
                                    backgroundColor: ["#ff0000", "#ff8400", "#1d9c34", "#5bff61"],
                                    borderWidth: 2,
                                    borderColor: '#3286ce',
                                    hoverOffset: 4
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: true,
                                    position: 'right',
                                    labels: {
                                        color: '#ffffff'
                                    }
                                }
                            },
                            rotation: -90,
                            circumference: 180,
                        }}
                    />
                  </MainWidget>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Tickets'} icon={'tickets'} url={'/'} image={'photo-1517502884422-41eaead166d4'} />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Tasks'} icon={'tasks'} url={'/'} image={'photo-1517502884422-41eaead166d4'} />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3} >
                  <MainWidget status={"primary"} title={'Cash'} icon={'cash'} url={'/'} image={'photo-1517502884422-41eaead166d4'} />
                </Grid>
              </Grid>
            </Box>
        </Box>
    </Layout>
  );
}