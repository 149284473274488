import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option,
    Sheet,
    Typography,
    Divider,
    Tabs,
    TabList,
    Tab,
    TabPanel
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/joy/IconButton';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../../template/layout';

import {
    GetStoreGroups,
    GetStoreGroup,
    CreateStoreGroup,
    UpdateStoreGroup, 
    DeleteStoreGroup,
    StoreGroup
} from '../../../api/storeGroups';
import AppDeleteItemModal from '../../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../../components/AceGrid';
import AppHeader from '../../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../../components/AppBreadcrumb';
import uuid from '../../../res/uuid';
import StoreGroupsList from './storeGroupsList';

const StoreGroups = () => {

    const defaultStore = {
        id: uuid(),
        organizationId: "",
        name: ""
    };

    const navigate = useNavigate();
    const [storeGroups, setStoreGroup] = useState<StoreGroup[]>([]);
    const [tmpStoreGroups, setTmpStoreGroups] = useState<StoreGroup[]>(storeGroups);
    const [openStoreGroupsModal, setOpenStoreGroupsModal] = useState(false);  
    const [modalStoreGroups, setModalStoreGroups] = useState<StoreGroup>(defaultStore); 
    const [modalLoading, setModalLoading] = useState(false);
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const organization_id = localStorage.getItem('organization_id') || "";
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);  

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };
  
    const fetchStoreGroups = async () => {
        setLoading(true);
        setStoreGroup([]);
        organization_id && GetStoreGroups(organization_id)
            .then(storeGroups => {
                setStoreGroup(storeGroups);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchStoreGroup = async (id: string) => {
        if(id){
            setOpenStoreGroupsModal(true);
            setModalLoading(true);
            organization_id && GetStoreGroup(id, organization_id)
                .then(storeGroup => {
                    setModalStoreGroups(storeGroup);
                    setModalLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const deleteStoreGroup = async (id: string) => {
        if(id){
            setLoading(true);            
            DeleteStoreGroup(id, localStorage.getItem('organization_id') || '')
                .then(user => {
                    fetchStoreGroups();
                    setOpenStoreGroupsModal(false);
                    setDeleteId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };
  
    const updateStore = async () => {
        // Use the same form to Create or Update Organization
        setLoading(true);
        if(editId){
            UpdateStoreGroup(modalStoreGroups)
                .then(store => {
                    fetchStoreGroups();
                    setOpenStoreGroupsModal(false);
                    setEditId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        } else {
            CreateStoreGroup(modalStoreGroups)
                .then(user => {
                    fetchStoreGroups();
                    setOpenStoreGroupsModal(false);     
                    setModalStoreGroups(defaultStore);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const newStoreGroup = () => {  
        setEditId(null);
        setDeleteId(null);      
        setModalStoreGroups(defaultStore);
        setOpenStoreGroupsModal(true);
    };
  
    useEffect(() => {
      fetchStoreGroups();
    },[]);
  
    useEffect(() => {      
        editId && fetchStoreGroup(editId);      
    },[editId]);
    
    useEffect(() => {
        setTmpStoreGroups(storeGroups);
    }, [storeGroups]);

    useEffect(() => {
        setTmpStoreGroups(storeGroups.filter((item:StoreGroup) => 
            (item?.name.toLowerCase().includes(searchByName.toLowerCase())
        )));
    }, [searchByName]);
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const setStoreGroupId = (id:string, name:string) => {
        localStorage.setItem('store_group_id', id);
        localStorage.setItem('store_group_name', name);
        navigate('/store-group-edges');
    };

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Store groups',
            url: '/store-groups',
            current: true
        },
    ];

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            type: 'string',
        }
    ];


    const renderFilters = () => (
      <Fragment>
      </Fragment>
    );

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1647100626362-d729d13dae86?auto=format&w=1000&dpr=2'}
                    title={'Store groups'}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchStoreGroups()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newStoreGroup()}
                    >
                        Add store group
                    </Button>
                </AppHeader>

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for store group</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpStoreGroups}
                    setStoreGroupId={setStoreGroupId}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'name'}
                    defaultSort={'desc'}
                    defaultId={'id'}
                />
            
                <StoreGroupsList storeGroups={tmpStoreGroups} setEditId={setEditId} setDeleteId={setDeleteId} />
            
            
            
            </Box>
            <Modal open={openStoreGroupsModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalStoreGroups(defaultStore);
                    setOpenStoreGroupsModal(false);
                    setEditId(null);
                    setDeleteId(null);  
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                <ModalClose />
                <DialogTitle>Store group</DialogTitle>
                {modalLoading && <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    />}
                    <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        updateStore();
                    }}
                    >
                    <DialogContent>Insert store group information</DialogContent>
                    <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalStoreGroups.name}
                        onChange={(e) => setModalStoreGroups({
                            ...modalStoreGroups,
                            organizationId: localStorage.getItem('organization_id') || '',
                            name: e.target.value}
                        )}
                        />
                    </FormControl>
                    <Button type="submit">Save</Button>
                    </Stack>
                    </form>
                </ModalDialog>
            </Modal>













            <AppDeleteItemModal
                title={'Delete zone'}
                description={'Are you sure you want to delete zone?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteStoreGroup}
            />
        </Layout>
    );
};

export default StoreGroups;