import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://device.api.acesolutions.no/api/Swagger/ui#/

export interface Device {
    id?: string | null,
    organizationId: string | null,
    storeId: string | null,
    store: string,
    deviceName: string,
    deviceType: string,
    zoneId: string | null,
    ioTDeviceId?: string | null,
    ioTPrimaryConnectionString?: string,
    ioTSecondaryConnectionString?: string,
    ioTPrimaryKey?: string,
    ioTSecondaryKey?: string,
    certificate?: string,
    status?: string,
    installedAt?: string,
    networkInfo: {
      ipAddress?: string | null,
      ipV6Address?: string | null,
      macAddress?: string | null,
    },
    serviceContractInfo: {
      contractId?: string | null,
      contractLevel?: string | null,
      expirationDate?: string | null,
    },
    deviceIlustrationUrl?: string,
    entryUUID?: string | null,
    exitUUID?: string | null,
    deviceStatus?: string,
    encodedDeviceData?: string,
    deviceSchema?: object,
    deviceUiSchema?: object,
};

export const GetAllDevices = async (org_id:string): Promise<Device[]> => {
    try {
        const { data } = await axios.get<Device[]>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch devices:', error);
        return [];
    }
}

export const GetDevice = async (org_id:string, deviceId: string): Promise<Device | null> => {
    try {
        const { data } = await axios.get<Device>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${deviceId}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device:', error);
        return null;
    }
}

export const CreateDevice = async (org_id:string, device: Device): Promise<Device | null> => {
    try {
        const { data } = await axios.post<Device>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices`, device, {
            headers: headers(localStorage.getItem('id_token'))
        });     
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create device:', error);
        return null;
    }
}

export const UpdateDevice = async (org_id: string, device: Device): Promise<Device | null> => {
    try {
        const { data } = await axios.put<Device>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${device.id}`, device, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update device:', error);
        return null;
    }
}

export const DeleteDevice = async (org_id: string, id: string): Promise<any> => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${id}`, {
            headers: headers(localStorage.getItem('id_token')),
            data: {}
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete device:', error);
        return null;
    }
}

export const GetDeviceTypes = async (): Promise<string[]> => {
    try {
        const { data } = await axios.get<string[]>(`${process.env.REACT_APP_DEVICE_API}/api/deviceTypes`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device types:', error);
        return [];
    }
}
