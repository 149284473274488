import * as React from 'react';
import { CircularProgress } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';

export default function DataGridLoading(props:any) {
  const { colSpan } = props;
  const is_loading = useSelector((state:any) => state.loading.is_loading);
  return is_loading && (    
    <tr>
      <td colSpan={colSpan}>
        <div className='loadingGrid'>
          <CircularProgress thickness={1} size='sm' />
          <small>Loading...</small>
        </div>
      </td>
    </tr>
  );
}